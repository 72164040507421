:root {
  --main-bg-color: rgb(127, 84, 54);
}

body {
  margin: 0;
  padding: 4px;
  background: var(--main-bg-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-button {
  border: 0;
  background: none;
}

a,
.fake-link {
  color: #ffd700;
}

a:has(h1) {
  text-decoration: none;
}
