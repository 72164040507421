h1 {
  text-align: center;
  font-size: 3rem;
}

.rainbow-text {
  color: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(
    300deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 35%,
    rgba(208, 222, 33, 1) 40%,
    rgba(79, 220, 74, 1) 45%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 55%,
    rgba(95, 21, 242, 1) 60%,
    rgba(186, 12, 248, 1) 75%,
    rgba(251, 7, 217, 1) 85%,
    rgba(255, 0, 0, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.05em transparent;
  letter-spacing: 0.1em;
}

.game-selection li:before {
  content: "🚽";
  margin-left: -20px;
  margin-right: 10px;
}

.game-selection ol {
  font-size: 2rem;
  font-weight: bold;
  list-style: none;
  padding: 0;
}

.game-selection section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-selection li {
  margin-bottom: 1rem;
}

.game-selection h2 {
  font-size: 2rem;
}

.game-selection section {
  margin: 0 2rem;
}

.bigger-text h1 {
  font-size: clamp(4rem, 10vmax, 5rem);
}

.bigger-text h2 {
  font-size: clamp(3rem, 7vmax, 4rem);
  text-align: center;
}
