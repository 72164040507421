.rainbow-button {
  --r: 20px;
  --ii: 0.5em;
  cursor: pointer;

  padding: 4px 8px;
  margin: var(--ii);

  background: transparent;
  color: black;
  border: 0 none;
  border-radius: var(--r);
  position: relative;
  transform: translate(0);
  transform-style: preserve-3d;
  display: inline-block;
}
.rainbow-button span {
  position: absolute;
  inset: calc(var(--ii) * -1);
  border: var(--ii) solid #0000;
  border-radius: calc(var(--ii) + var(--r));
  transform: translateZ(-1px);
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.rainbow-button:hover span:before {
  inset: -6px;
  background: -webkit-linear-gradient(
    290deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 35%,
    rgba(208, 222, 33, 1) 40%,
    rgba(79, 220, 74, 1) 45%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 55%,
    rgba(95, 21, 242, 1) 60%,
    rgba(186, 12, 248, 1) 75%,
    rgba(251, 7, 217, 1) 85%,
    rgba(255, 0, 0, 1) 100%
  );
}

.rainbow-button span:before {
  transition: inset 0.4s ease;
}

.rainbow-button span:before {
  content: "";
  position: absolute;
  inset: 0px;
  transform: translate(0, 0);
  background: -webkit-linear-gradient(
    300deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 35%,
    rgba(208, 222, 33, 1) 40%,
    rgba(79, 220, 74, 1) 45%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 55%,
    rgba(95, 21, 242, 1) 60%,
    rgba(186, 12, 248, 1) 75%,
    rgba(251, 7, 217, 1) 85%,
    rgba(255, 0, 0, 1) 100%
  );
  filter: blur(calc(var(--ii) / 2));
  border-radius: var(--r);
}
