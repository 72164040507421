particle {
  border-radius: 50%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  opacity: 0;
}

.tap-that {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Take over the whole screen */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup-word {
  font-size: clamp(2rem, 15vw, 4rem);
  text-align: center;
  opacity: 0;
  margin: 0 20px;
  user-select: none;
}

.instructions {
  font-size: 1.5rem;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instructions .cta-container {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.instructions h2 {
  font-size: 2rem;
}

.instructions section {
  margin: 0 2rem;
}

.instructions .cta {
  text-align: center;
  font-size: 2rem;
  display: inline-block;
}

.game-over {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}

.game-over .cta {
  font-size: 2rem;
}

.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
}

.score-time-container {
  position: fixed;
  bottom: 4px;
  left: 8px;
  right: 8px;
  user-select: none;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.score-time-container .score {
  font-size: 1.5rem;
}

.high-score section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.high-score ol {
  padding: 0;
  margin-top: 0;
  width: 100%;
  min-width: 290px;
  max-width: 400px;
  text-align: center;
}

.high-score li {
  padding: 0 10px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.high-score button {
  font-size: 2rem;
}
